import type { FetchError } from 'ofetch'
import type { ApiErrors } from '~/types/api.type'

export default function<T>(error: Ref<FetchError<any> | null>): ApiErrors<T> {
  const errorArrays: {
    [K in keyof T]: string[]
  } = error.value?.data?.errors || {}

  const errors: ApiErrors<T> = {}
  for (const key in errorArrays)
    errors[key as keyof T] = errorArrays[key as keyof T][0]

  return errors
}
